svg {
  color: inherit;
}
a.social-link {
  margin-left: 5px;
  min-width: 35px;
  display: inline-block;
}
a.social-link:first-child {
  margin-left: 0;
}
a.social-link:hover,
a.social-link:focus,
.social-link-icon:hover,
.social-link-icon:focus {
  text-decoration: none;
}
a.social-link.no-print {
  display: inline-block;
}
a.social-link .print-only {
  display: none;
}
@media print {
  a.social-link {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .social-link-icon {
    align-self: center;
    transform: scale(0.8);
  }
  .social-link-text {
    padding-left: 1em;
    font-size: 11px;
    height: inherit;
    align-self: center;
    vertical-align: middle;
  }
  a.social-link .print-only {
    display: inline-block;
    padding-right: 15px;
  }
  a.social-link.no-print {
    display: none;
  }
}
.link-github {
  color: #454545;
}
.link-github:hover,
.link-github:focus {
  color: #2b2b2b;
}
.link-github2 {
  color: #454545;
}
.link-github2:hover,
.link-github2:focus {
  color: #2b2b2b;
}
.link-twitter {
  color: #33ccff;
}
.link-twitter:hover,
.link-twitter:focus {
  color: #00bfff;
}
.link-bluesky {
  color: #2c6cff;
}
.link-bluesky:hover,
.link-bluesky:focus {
  color: #0c4cff;
}
.link-blog {
  color: #f36f24;
}
.link-blog:hover,
.link-blog:focus {
  color: #d8560c;
}
.link-website {
  color: #0052a3;
}
.link-website:hover,
.link-website:focus {
  color: #003870;
}
.link-loyno {
  color: #660000;
}
.link-loyno:hover,
.link-loyno:focus {
  color: #f4aa00;
}
.link-loynodir {
  color: #660000;
}
.link-loynodir:hover,
.link-loynodir:focus {
  color: #f4aa00;
}
.link-pluralsight {
  color: #0052a3;
}
.link-pluralsight:hover,
.link-pluralsight:focus {
  color: #003870;
}
.link-bountysource {
  color: #0052a3;
}
.link-bountysource:hover,
.link-bountysource:focus {
  color: #003870;
}
.link-linkedin {
  color: #007bb6;
}
.link-linkedin:hover,
.link-linkedin:focus {
  color: #005983;
}
.link-skype {
  color: #12a5f4;
}
.link-skype:hover,
.link-skype:focus {
  color: #0986ca;
}
.link-stackoverflow {
  color: #8e8e92;
}
.link-stackoverflow:hover,
.link-stackoverflow:focus {
  color: #747479;
}
.link-soundcloud {
  color: #e8822d;
}
.link-soundcloud:hover,
.link-soundcloud:focus {
  color: #cc6916;
}
.link-youtube {
  color: #dd4b39;
}
.link-youtube:hover,
.link-youtube:focus {
  color: #c23321;
}
.link-pinterest {
  color: #bd091f;
}
.link-pinterest:hover,
.link-pinterest:focus {
  color: #8c0717;
}
.link-twitch {
  color: #9147ff;
}
.link-twitch:hover,
.link-twitch:focus {
  color: #a970ff;
}
.link-vimeo {
  color: #17b3e8;
}
.link-vimeo:hover,
.link-vimeo:focus {
  color: #128fba;
}
.link-behance {
  color: #2c98cf;
}
.link-behance:hover,
.link-behance:focus {
  color: #2379a5;
}
.link-codepen {
  color: #1c1c1c;
}
.link-codepen:hover,
.link-codepen:focus {
  color: #020202;
}
.link-foursquare {
  color: #fa4778;
}
.link-foursquare:hover,
.link-foursquare:focus {
  color: #f91554;
}
.link-reddit {
  color: #545454;
}
.link-reddit:hover,
.link-reddit:focus {
  color: #3b3b3b;
}
.link-spotify {
  color: #1ed760;
}
.link-spotify:hover,
.link-spotify:focus {
  color: #0eff56;
}
.link-tumblr {
  color: #2d4661;
}
.link-tumblr:hover,
.link-tumblr:focus {
  color: #1d2d3e;
}
.link-dribbble {
  color: #ce366f;
}
.link-dribbble:hover,
.link-dribbble:focus {
  color: #a82959;
}
.link-facebook {
  color: #4b6daa;
}
.link-facebook:hover,
.link-facebook:focus {
  color: #3b5687;
}
.link-angellist {
  color: #000000;
}
.link-angellist:hover,
.link-angellist:focus {
  color: #000000;
}
.link-bitbucket {
  color: #205081;
}
.link-bitbucket:hover,
.link-bitbucket:focus {
  color: #163758;
}
.link-goodreads {
  color: #372213;
}
.link-goodreads:hover,
.link-goodreads:focus {
  color: #4f301a;
}
