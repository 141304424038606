.contact-details {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr;
}
.contact-details .detail {
  padding: 10px 0;
}
.contact-details .detail .icon {
  padding: 0 10px;
  color: #3d8b3d;
}
.contact-details .detail .info {
  font-size: 0.8em;
}
.contact-details .detail .icon,
.contact-details .detail .info {
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .contact-details {
    text-align: center;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
  }
  .contact-details .detail {
    min-height: 1px;
  }
}
@media screen and (max-width: 992px) {
  .contact-details {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 100%;
  }
  .contact-details .detail .icon {
    padding: 0 5px 0 0;
  }
  .contact-details .detail .icon,
  .contact-details .detail .info {
    display: inline-block;
  }
  .contact-details>.detail {
    min-height: 1px;
    width: 100%;
    display: inline-block;
  }
}

@media print {
  .contact-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 2em;
    min-width: 3in;
    width: auto;
    text-align: left;
  }
  .contact-details .detail {
    min-height: 1px;
    padding: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}
