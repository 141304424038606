@media screen and (min-width: 993px) {
  .resume-card-wrapper {
    overflow-y: scroll;
  }
}
@media print {
  .resume-card-wrapper {
    height: auto;
    overflow-y: inherit;
  }
}
.resume-card {
  padding: 10px 20px;
}
.resume-details .detail {
  display: grid;
  grid-template-columns: max-content 1fr;

  padding: 10px 0;
}
.resume-details .detail .icon {
  min-width: 45px;
  max-width: 45px;
  text-align: center;
}
.resume-details .detail .info {
  width: 100%;
}
.resume-details .detail .title,
.resume-details .detail .icon {
  color: #3d8b3d;
}
.resume-details .detail .title {
  margin-top: 0;
  margin-bottom: 1.5em;
}
.resume-details .detail .title .grey {
  color: #333333;
  font-size: 0.8em;
  padding-left: 20px;
}
.resume-details .detail .mobile-title {
  display: none;
}
@media screen and (max-width: 480px) {
  .resume-details .detail .mobile-title {
    display: inline-block;
    margin-left: 5px;
    font-weight: bold;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .resume-details .detail .mobile-title .grey {
    color: #333333;
  }
}
.resume-details .detail .icon,
.resume-details .detail .info {
  display: table-cell;
  padding: 0 10px;
}
@media screen and (max-width: 480px) {
  .resume-details .detail {
    display: block;
  }
  .resume-details .detail .icon {
    max-width: inherit;
    min-width: inherit;
    text-align: left;
  }
  .resume-details .detail .icon,
  .resume-details .detail .info {
    display: block;
    padding: 10px 0;
  }
  .resume-details .detail .title {
    display: none;
  }
}

ul.list-unstyled {
  padding-inline-start: 0;
}
ul.list-unstyled>li {
  list-style-type: none;
}
ul.list-unstyled>li.card-nested {
  padding-left: 0;
}