.skill-level {
  margin-left: 0;
  margin-top: 5px;
  height: 1.2em;
  width: 100%;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}
.skill-level .skill-progress {
  border-radius: 10px;
  width: 0%;
  height: 1em;
  transition: width 1s ease;
  -webkit-transition: width 1s ease;
  font-weight: bold;
  padding-left: 10px;
  padding-bottom: 2px;
}
.skill-level .skill-progress.beginner {
  width: 50%;
  background: #e74c3c;
  color: white;
}
.skill-level .skill-progress.intermediate {
  width: 75%;
  background: #f1c40f;
  color: black;
}
.skill-level .skill-progress.advanced {
  width: 85%;
  background: #3d8b3d;
  color: white;
}
.skill-level .skill-progress.mastery {
  width: 95%;
  background: #225bca;
  color: white;
}

@media print {
  .skill-level {
    border: 1px solid black;
  }
  .skill-level .skill-progress {
    -webkit-print-color-adjust: exact !important;
    border: 1px solid black;
  }
}
