.no-print {
  display: inherit;
}

@media print {
  .no-print {
    display: none;
  }
  .info .content.has-sidebar {
    border-right: none;
  }
}