.clear-margin {
  margin: 0;
}
.space-top {
  margin-top: 10px;
}
.space-right {
  margin-right: 10px;
}
.text-bolder {
  font-weight: bold;
}
.text-center {
  text-align: center;
}
.icon-left {
  margin-right: 5px;
}
.icon-right {
  margin-left: 5px;
}
.text-muted {
  color: #999;
}
.text-uppercase {
  text-transform: uppercase;
}
.affix {
  position: fixed;
  transform: translate3d(0,0,0);
}
.labels {
  word-spacing: 5px;
  line-height: 2;
}
.label-keyword {
  display: inline-block;
  background: #3d8b3d;
  color: white;
  font-size: 0.9em;
  padding: 0 0.5em;
  line-height: 1.5;
  margin-right: 8px;
  border: 1px solid #1e441e;
  border-radius: 20px;
}
.link-disguise {
  color: inherit;
}
.link-disguise:hover {
  color: inherit;
}
.print-only {
  display: none;
}
body {
  font-family: 'Lato', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, sans-serif;
  background: #f0f0f0;
  color: #333333;
}
a {
  color: #3d8b3d;
  text-decoration: none;
}
a:hover {
  color: #1e441e;
}
.main {
  padding: 5px;
}
.card {
  background: #ffffff;
  border: 1px solid #f6f6f6;
  border-radius: 3px;
  min-height: 300px;
  padding: 10px;
}
.card-nested {
  min-height: 0;
  margin-bottom: 10px;
  border-width: 1px 0 0 0;
}
.card-nested:before,
.card-nested:after {
  content: " ";
  display: table;
}
.card-nested:after {
  clear: both;
}
.card-nested .content {
  margin-top: 15px;
}
.link-separator {
  color: #808080;
  font-size: 0.8em;
  padding: 0 10px;
}
.card-wrapper {
  padding: 5px;
}
.text-bolder {
  font-weight: bold;
}
.info .content.has-sidebar {
  width: 80%;
  box-sizing: border-box;
  float: left;
  padding: 0 10px;
  border-right: 1px solid #dddddd;
}
.has-sidebar .hide-when-sidebar {
  display: none;
}
.sidebar-date {
  text-align: left;
  margin-left: 1.5em;
  display: block;
  width: 100%;
}
.sidebar-date-label {
  text-align: left;
  display: block;
  width: 100%;
  font-weight: bold;
}
@media screen and (max-width: 992px) {
  .info .sidebar {
    display: none;
  }
  .info .hide-when-sidebar {
    display: block;
  }
}
@media screen and (min-width: 993px) {
  .info .sidebar {
    margin-left: 80%;
    box-sizing: border-box;
    padding: 10px;
  }
}
.name {
  color: #3d8b3d;
}
.card-skills {
  position: relative;
}
.skill-info {
  margin-left: 10px;
}
p.institution {
  font-variant: small-caps;
}
p.study-type {
  font-weight: 900;
}
p.study-area {
  font-size: 1.2em;
}
#publications .content i.fa {
  color: #3d8b3d;
  padding-right: 10px;
}
.reference-relationship {
  font-size: 0.7em;
  text-transform: uppercase;
  margin-left: 15px;
}
#resume-container {
  display: grid;

}
@media screen and (max-width: 480px) {
  .card-nested {
    padding: 5px 0;
  }
  .info .content.has-sidebar {
    padding: 0 2px;
  }
  .skill-info {
    margin-left: 20px;
  }
  ul {
    padding-left: 25px;
  }
  .hidden-xs,
  .hidden-sm {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .profile-card-wrapper {
    height: auto;
    /* overflow-y: auto; */
  }
  .quote {
    font-size: inherit;
  }
  .hidden-sm {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  #resume-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    height: 100vh;
    overflow-y: scroll;
  }
  .clear-margin-sm {
    margin-bottom: 0;
  }
  .card-wrapper {
    float: none !important;
  }
  .info .content.has-sidebar {
    width: 100%;
    border-right: 0;
  }
  .profile-card-wrapper {
    position: relative;
  }
  .name {
    margin-top: 10px;
  }
}
@media screen and (min-width: 993px) {
  #resume-container {
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    grid-gap: 5px;
    width: 100vw;
    height: 100vh;
  }
}
@media print {
  html {
    height: 100%;
  }
  body {
    width: 8in;
    font-size: 11pt;
    height: inherit;
  }
  a[href]:after {
    content: none !important;
  }
  p {
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
  }
  .card,
  .card-nested {
    border: none;
    page-break-inside: avoid;
  }
  .card-nested {
    margin-bottom: 0;
  }
  .profile-card {
    text-align: left;
  }
  .name-and-profession {
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
  }
  .floating-nav,
  .floating-nav-trigger {
    display: none;
  }
  .pagebreak {
    page-break-before: always;
  }
  .no-pagebreak {
    page-break-inside: avoid;
  }
  .label {
    border: none;
  }
  .labels .label.label-boxed {
    border: 1px solid black;
    border-radius: 10px;
    margin: 1px;
    margin-right: 0.5em;
    padding: 0 5px;
    display: inline-block;
  }
  .no-print {
    display: none;
  }
  .for-print {
    display: inherit !important;
  }
  ul.list-unstyled {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  ul.list-unstyled>li.card.card-nested {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 1em;
  }
  .content.has-sidebar {
    padding-top: 0;
    margin-top: 0;
  }
  #resume-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    height: auto;
    overflow-y: inherit;
    scrollbar-width: 0;
  }
  .info .content.has-sidebar {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    border-right: none;
  }
  .has-sidebar .hide-when-sidebar {
    display: none;
  }  
}
