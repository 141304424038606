/*
    Credits: http://codyhouse.co/demo/smart-fixed-navigation/index.html
*/
.floating-nav-trigger {
  width: 50px;
  height: 50px;
  -webkit-box-shadow: 0 1px 4px rgba(30, 68, 30, 0.24);
  box-shadow: 0 1px 4px rgba(30, 68, 30, 0.24);
  -webkit-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  display: block;
  border-radius: 50%;
  text-align: center;
  padding: 0;
  background-color: white;
  font-size: 1.2em;
  color: #3d8b3d;
  z-index: 100;
  position: fixed;
  top: 20px;
  right: 40px;
  border: 1px solid #1e441e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.floating-nav-trigger .open-icon {
  font-size: 1.2em;
  font-weight: normal;
  width: 35px;
  height: 35px;
}
.floating-nav-trigger .close-icon {
  display: none;
  font-size: 1em;
  font-weight: normal;
  width: 30px;
  height: 30px;
}
.floating-nav-trigger:hover {
  color: #1e441e;
  background-color: #accfac;
  text-decoration: none;
}
.floating-nav-trigger:focus {
  outline: none;
}
.floating-nav-trigger.is-open {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.floating-nav-trigger.is-open .close-icon {
  display: inherit;
}
.floating-nav-trigger.is-open .fa-bars {
  display: none;
}
.floating-nav-trigger.is-open:hover {
  background-color: #accfac;
}
.floating-nav {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 100% 0%;
  -moz-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  -webkit-transition: transform 0.3s, visibility 0s 0.3s;
  -o-transition: transform 0.3s, visibility 0s 0.3s;
  transition: transform 0.3s, visibility 0s 0.3s;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);
  position: fixed;
  z-index: 50;
  right: 10px;
  top: 10px;
  background-color: white;
  width: 90%;
  max-width: 450px;
  visibility: hidden;
  overflow: hidden;
}
.floating-nav ul {
  font-size: 1.2em;
  margin: 0;
}
.floating-nav ul li {
  border-bottom: 1px solid #eff2f6;
}
.floating-nav ul li:hover {
  background-color: #accfac;
}
.floating-nav ul li a {
  padding: 20px;
  text-decoration: none;
  color: #3d8b3d;
  display: block;
}
.floating-nav ul li a:hover {
  color: #1e441e;
}
.floating-nav ul li a .navigation-icon {
  min-width: 2em;
  margin-right: 0.5em;
}
.floating-nav ul li:last-child {
  border-bottom: 0;
}
.floating-nav.is-visible {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: transform 0.3s, visibility 0s 0s;
  -o-transition: transform 0.3s, visibility 0s 0s;
  transition: transform 0.3s, visibility 0s 0s;
  visibility: visible;
}
small {
  font-variant: small-caps;
  font-weight: bold;
  font-size: 0.8rem;
}
