.social-links {
  line-height: 2.5;
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  justify-self: center;
}

@media print {
  .social-links {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 2em;
    margin-top: 0;
  }
}
