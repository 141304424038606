.profile-info-container {
  display: flex;
  flex-direction: column;
}
.spacer {
  width: 0;
  height: 20px;
}

@media print {
  .profile-card-wrapper {
    height: fit-content;
    overflow-y: inherit;
  }
  .profile-info-container {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .spacer {
    width: 5em;
  }
}
